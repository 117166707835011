import { useConfirm } from 'primevue/useconfirm'

export default () => {
  const confirm = useConfirm()

  const confirmCloseEditor = (event, accept, reject, message, icon, acceptClass) =>
    confirm.require({
      target: event.currentTarget,
      message: message || 'You have unsaved changes. Do you want to proceed?',
      icon: icon || 'pi pi-exclamation-circle',
      acceptClass: acceptClass || 'p-button-danger',
      accept: accept,
      reject: reject
    })

  const confirmDeleteItem = (event, accept, reject, message, icon, acceptClass) =>
    confirm.require({
      target: event.currentTarget,
      message: message || 'Do you want to delete this record?',
      icon: icon || 'pi pi-exclamation-circle',
      acceptClass: acceptClass || 'p-button-danger',
      accept: accept,
      reject: reject
    })

  return { confirmCloseEditor, confirmDeleteItem }
}
