<template>
  <Dialog
    contentClass="c-dialog"
    :visible="visible"
    :closeOnEscape="false"
    :closable="false"
    :modal="true"
    :showHeader="false"
    :breakpoints="{ '992px': '75vw', '768px': '100vw' }"
    :style="{ width: '60vw' }"
  >
    <Card>
      <template #title>
        <slot name="title">
          <div class="p-d-flex p-ai-center p-jc-between p-px-3 p-pt-3">
            <span data-test="editor-title">{{ title }}</span>
            <Button
              v-if="closeButton"
              data-test="editor-close-button"
              class="p-button-rounded p-button-text"
              icon="pi pi-times"
              @click="handleClose"
            />
          </div>
        </slot>
      </template>
      <template #content>
        <div class="p-grid p-fluid p-px-3 p-pt-3">
          <slot name="content"></slot>
        </div>
      </template>
      <template #footer>
        <div class="actions p-d-flex p-jc-end p-ai-center p-px-3 p-pb-3" style="gap: 0.8rem">
          <slot name="footer"></slot>
        </div>
      </template>
    </Card>
  </Dialog>
</template>

<script setup>
import confirmPopup from '../hooks/useConfirmPopup'

const emit = defineEmits(['close'])
const props = defineProps({
  visible: { type: Boolean, required: true, default: false },
  title: { type: String, required: false },
  closeButton: { type: Boolean, default: true },
  showConfirmClose: { type: Boolean, default: false },
  confirmCloseMessage: { type: String, required: false }
})

const { confirmCloseEditor } = confirmPopup()

const handleClose = ($event) => {
  if (props.showConfirmClose) {
    confirmCloseEditor(event, () => emit('close', false), null, props.confirmCloseMessage)
  } else {
    emit('close', false)
  }
}
</script>

<script>
export default {
  name: 'ModalEditor'
}
</script>

<style scoped>
</style>
